import { Route, Routes } from "react-router-dom";
import { PageNotFound } from "@datwyler/mfe-shared-components";
import Telemetry from "./container/Telemetry";

const Router = (props: any) => {
  return (
    <Routes>
      <Route path="/telemetry" element={<Telemetry />} />{" "}
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Router;
