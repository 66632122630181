import { Row, Col } from "antd";
import {
  BasicDataTable,
  getAllSites,
  useIntl,
  getRequiredDateFormat,
} from "@datwyler/mfe-shared-components";

const TelemetryTable = (props) => {
  const {
    data,
    locations,
    pageNumber,
    rowsPerPage,
    setRowsPerPage,
    setPageNumber,
    devices,
  } = props;
  const intl = useIntl();

  const onChangeRowsPerPage = (val) => {
    setRowsPerPage(parseInt(val));
    setPageNumber(1);
  };

  const onChangePage = (val) => {
    setPageNumber(val);
  };

  const getSiteNameByDeviceId = (deviceId) => {
    const allSites = getAllSites(locations);

    const device = devices?.find((device) => {
      return device.id === deviceId;
    });

    if (device) {
      const site = allSites.find((site) => {
        return site.id === device?.site.id;
      });
      if (site?.name) return site.name;
    }

    return "";
  };

  const getTableData = () => {
    if (data?.telemetries.length > 0 && devices?.length > 0) {
      return (
        data.telemetries.map((telemetry) => {
          const withKey = JSON.parse(JSON.stringify(telemetry));
          withKey.key = withKey.id;
          withKey.site = getSiteNameByDeviceId(telemetry?.device?.id);
          withKey.date = getRequiredDateFormat(
            telemetry?.time,
            "YYYY-MM-DD HH:mm:ss"
          );
          withKey.name = telemetry?.name
            ? intl.formatMessage({ id: telemetry.name })
            : "";
          return withKey;
        }) || []
      );
    }

    return [];
  };

  const columns = [
    {
      title: intl.formatMessage({ id: "site" }),
      dataIndex: "site",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "device" }),
      dataIndex: ["device", "name"],
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "parameter" }),
      dataIndex: "name",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "value" }),
      dataIndex: "value",
      ellipsis: true,
    },
    {
      title: intl.formatMessage({ id: "timestamp" }),
      dataIndex: "date",
      ellipsis: true,
    },
  ];

  return (
    <Row style={{ marginTop: "24px" }}>
      <Col span={24}>
        {data?.telemetries?.length > 0 && (
          <BasicDataTable
            data={getTableData()}
            columns={columns}
            pageNumber={pageNumber}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangePage={onChangePage}
            total={data?.page?.totalElements}
            style={{ cursor: "pointer" }}
          />
        )}
      </Col>
    </Row>
  );
};

export default TelemetryTable;
