import { Col, Row, Typography } from "antd";
import {
  DownloadIcon,
  useIntl,
  useScreenOptions,
  BasicButton,
} from "@datwyler/mfe-shared-components";
import Filters from "../Filters";

const { Title } = Typography;

const Header = (props) => {
  const {
    fetchDataForExcel,
    locations,
    filters,
    setFilters,
    setPageNumber,
    getDevices,
    devices,
    isFetchDeviceLoading,
  } = props;
  const intl = useIntl();
  const { isMobileScreen } = useScreenOptions();

  return (
    <Row style={{ marginBottom: isMobileScreen ? "16px" : "24px" }}>
      <Col xs={24} sm={24} md={24} lg={24} xl={12}>
        <Title level={2} style={{ marginTop: "0px" }}>
          {intl.formatMessage({ id: "menu_telemetry" })}
        </Title>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={12}
        style={{
          justifyContent: isMobileScreen ? "left" : "right",
          marginTop: isMobileScreen ? "20px" : "0px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {isMobileScreen && (
          <Filters
            locations={locations}
            setPageNumber={setPageNumber}
            filters={filters}
            setFilters={setFilters}
            getDevices={getDevices}
            devices={devices}
            isFetchDeviceLoading={isFetchDeviceLoading}
          />
        )}
        <BasicButton
          style={{
            marginRight: "16px",
            marginLeft: isMobileScreen ? "0px" : "16px",
          }}
          onClick={fetchDataForExcel}
          icon={<DownloadIcon width={"24px"} height={"24px"} />}
        >
          {intl.formatMessage({ id: "export" })}
        </BasicButton>
      </Col>
    </Row>
  );
};

export default Header;
