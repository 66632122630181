import { useContext, useEffect, useState } from "react";
import { Flex, Row, Col, DatePicker } from "antd";
import {
  BasicSelectMultiple,
  useIntl,
  BasicButton,
  BasicFilter,
  getSiteOptions,
  NotificationContext,
  useLoadingGif,
  moment,
} from "@datwyler/mfe-shared-components";
import { FILTERDEFAULTS } from "../../utils/constants";
import * as dayjs from "dayjs";

const Filters = (props) => {
  const {
    filters = {},
    locations,
    setPageNumber,
    setFilters,
    getDevices,
    devices,
    isFetchDeviceLoading,
  } = props;
  const { openNotification }: any = useContext(NotificationContext);
  const intl = useIntl();

  const [isShowFiltersDialog, setIsShowFiltersDialog] = useState(false);
  const [interimFilters, setInterimFilters] = useState({ ...filters });
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [parameterOptions, setParameterOptions] = useState([]);
  const { LoadingGif, setIsLoading } = useLoadingGif();

  useEffect(() => {
    setIsLoading(isFetchDeviceLoading || false);
  }, [isFetchDeviceLoading]);

  useEffect(() => {
    if (devices && interimFilters?.sites?.length > 0) {
      const deviceOptions = [];
      if (devices.length > 0) {
        const deviceIds = []; // for checking if current filters exists
        devices.forEach((device, index) => {
          deviceOptions.push({
            priority: index,
            label: device.name,
            value: device.id,
          });
          deviceIds.push(device.id);
        });

        if (interimFilters.devices?.length > 0) {
          const newDevices = [];
          interimFilters.devices.forEach((d) => {
            if (deviceIds.includes(d)) {
              newDevices.push(d);
            }
          });
          setInterimFilters({ ...interimFilters, devices: newDevices });
        }
      } else {
        openNotification({
          type: "error",
          description: intl.formatMessage({ id: "no_device" }),
          placement: "topRight",
        });
      }
      setDeviceOptions(deviceOptions);

      if (interimFilters?.devices) {
        setParameterOptions(getParameterOptions(interimFilters.devices));
      }
    }
  }, [devices]);

  const handleChangeSite = (sites: Array<string>) => {
    if (sites.length === 0) {
      setInterimFilters(FILTERDEFAULTS);
      setDeviceOptions([]);
      setParameterOptions([]);
      return;
    } else {
      setInterimFilters({ ...interimFilters, sites: sites });
      getDevices(sites);
    }
  };

  const handleChangeDevice = (devices: Array<string>) => {
    if (devices.length === 0) {
      setParameterOptions([]);
      setInterimFilters({
        ...interimFilters,
        devices: [],
        parameters: [],
        startTime: null,
      });
    } else {
      setInterimFilters({ ...interimFilters, devices: devices });
      setParameterOptions(getParameterOptions(devices));
    }
  };

  const handleChangeParameter = (parameters: Array<string>) => {
    if (parameters.length === 0) {
      setInterimFilters({ ...interimFilters, parameters: [], startTime: null });
    } else {
      setInterimFilters({ ...interimFilters, parameters: parameters });
    }
  };

  const handleChangeDate = (date) => {
    setInterimFilters({
      ...interimFilters,
      startTime: date?.startOf("day")?.unix() || null,
    });
  };

  const getParameterOptions = (selectedDevices) => {
    const parameterOptions = [];
    const checkedParameters = [];
    if (selectedDevices?.length > 0) {
      selectedDevices.forEach((deviceId) => {
        const device = devices?.find((dev) => dev.id === deviceId);
        device?.telemetry?.forEach((t) => {
          if (!checkedParameters.includes(t.name)) {
            parameterOptions.push({
              priority: parameterOptions.length + 1,
              label: intl.formatMessage({ id: t.name }),
              value: t.name,
            });
            checkedParameters.push(t.name);
          }
        });
      });
    }
    return parameterOptions;
  };

  const handleReset = () => {
    setPageNumber(1);
    setFilters(FILTERDEFAULTS);
    setInterimFilters(FILTERDEFAULTS);
    setDeviceOptions([]);
    setParameterOptions([]);
  };

  const handleApply = () => {
    setPageNumber(1);
    setFilters(interimFilters);
    setIsShowFiltersDialog(false);
  };

  const FilterContent = () => (
    <Flex style={{ width: "360px" }}>
      <LoadingGif />
      <Row style={{ width: "100%" }} gutter={[0, 16]}>
        <Col span={24}>
          <BasicSelectMultiple
            defaultValue={interimFilters.sites}
            options={getSiteOptions(locations)}
            placeholder={intl.formatMessage({ id: "select_site" })}
            onChange={handleChangeSite}
          />
        </Col>
        {deviceOptions.length > 0 && (
          <Col span={24}>
            <BasicSelectMultiple
              defaultValue={interimFilters.devices}
              options={deviceOptions}
              placeholder={intl.formatMessage({ id: "devices" })}
              onChange={handleChangeDevice}
            />
          </Col>
        )}
        {parameterOptions.length > 0 && (
          <Col span={24}>
            <BasicSelectMultiple
              defaultValue={interimFilters.parameters}
              options={parameterOptions}
              placeholder={intl.formatMessage({ id: "parameter" })}
              onChange={handleChangeParameter}
            />
          </Col>
        )}
        {interimFilters?.parameters.length > 0 && (
          <Col span={24}>
            <DatePicker
              size="large"
              style={{ height: 56, width: "100%" }}
              disabledDate={(current) => {
                return current && current > moment().endOf("day");
              }}
              onChange={handleChangeDate}
              value={
                interimFilters?.startTime
                  ? dayjs.unix(interimFilters?.startTime)
                  : null
              }
              placeholder={intl.formatMessage({ id: "select_date" })}
            />
          </Col>
        )}
        <Col span={24} style={{ textAlign: "right" }}>
          <BasicButton onClick={handleReset} style={{ minWidth: "120px" }}>
            {intl.formatMessage({ id: "reset" })}
          </BasicButton>
          <BasicButton
            type={"primary"}
            style={{ marginLeft: "16px", minWidth: "120px" }}
            onClick={handleApply}
          >
            {intl.formatMessage({ id: "apply" })}
          </BasicButton>
        </Col>
      </Row>
    </Flex>
  );

  return (
    <Row>
      <Col span={24} style={{ display: "flex", alignItems: "center" }}>
        <BasicFilter
          content={<FilterContent />}
          open={isShowFiltersDialog}
          setOpen={setIsShowFiltersDialog}
          overlayStyle={{ width: "auto" }}
        />
      </Col>
    </Row>
  );
};

export default Filters;
